













import CoreAppBar from "./core/CoreAppBar.vue";
import CoreDrawer from "./core/CoreDrawer.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    CoreAppBar,
    CoreDrawer,
  },

  data() {
    return {
      drawer: null,
      showMenu: true,
    };
  },

  created() {
    // Получение глобальных GET параметров
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("showMenu")) {
      this.showMenu = true;
    }
  },
});
