






























import { menu } from "@/configs";
import Vue from "vue";

export default Vue.extend({
  name: "MainMenu",

  data() {
    return { menu: menu };
  },
});
